<template>
  <div>
    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    <title-area/>
    

    <section class="appie-about-page-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-about-page-content">
                        <h3 class="title">Join our team to create the best digital solutions.</h3>
                        <p>Roftr was founded in the year 2004 with the motto to serve the world with the solution that is quite sensible and feasible. We believe in three things. They are: Smart work, exceeding our clients' expectations positively and offering the complete enterprise solution on time. We are building value, making our customers profit not tomorrow, but from today.</p>
                        <p>We provide quality solution that are sensible and that makes a positive impact about you amongst your customers. We do have the professionals who know what they are doing and who truly believe in what they do, not just in their paychecks.</p>
                        <p>We don't have employees who are just developing the sites for hobbies or as part-time. We have true professionals who believe in experiments and values. We assure that you will find elegant craftsmanship in our solutions which makes sense and satisfies your need.</p>
                        <p>We have defined several benchmarks and measured great heights in very short period that speaks volume about our development.
                           We came through fields like E-Commerce Applications like Online shopping website, Online movie ticket booking for theatre, Financial Applications for Loans, ERP Applications for Small/Medium/Large Enterprises, Hospitals and Clinics, Schools and colleges, Internet Payment Gateway, API Integrations, RealEstate and Property Builders, and more...
                           </p>

                            <p>We believe in long-term partnership with our clients. So, we will never hesitate to communicate with you through email or phone. Clients and client satisfaction is our ultimate goal, so we will satisfy our clients at any cost. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <service-aboutus/>
    <why-choose-roftr/>
    
    <footer-home-two class="appie-footer-area"/>
    
    
    <div class="back-to-top">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div> 
</template>

<script>
import FooterHomeTwo from '../Footer/FooterHomeTwo.vue'
import HeaderProduct from '../Header/HeaderProduct.vue'
import SidebarHomeTwo from '../Sidebar/SidebarHomeTwo.vue'
import WhyChooseRoftr from './WhyChooseRoftr.vue'
import ServiceAboutus from './ServiceAboutus.vue'
import TitleArea from './TitleArea.vue'
export default {
	components: { SidebarHomeTwo, HeaderProduct, TitleArea, ServiceAboutus, WhyChooseRoftr, FooterHomeTwo },
    data(){
        return{
            sidebar:false,
            
            navs:[
            {
                name:'home',
                path:'/'
            },
            {
                name:'About us',
                path:'/about-us'
            },
            {
                name:'Zoho',
                path:'/zoho'            
            },
            {
                name:'Industries',
                    childrens:[
                    {
                        name:'Retail and E-Commerce',
                        path:'/product/e-commerce'
                    },
                    {
                        name:'Financial Services',
                        path:'/product/financial-services'
                    },
                    {
                        name:'Online Movie Ticket Booking',
                        path:'/product/online-ticket-booking'
                    },
                    /*{ 
                        name:'News Media',
                        path:''
                    },
                    { 
                        name:'Static Website',
                        path:''
                    },
                    { 
                        name:'Custom Web Application',
                        path:''
                    },
                    { 
                        name:'Travel and Hospitality',
                        path:''
                    },
                    { 
                        name:'Real Estate / Property Builders',
                        path:''
                    },
                    { 
                        name:'Hospitals and Clinics',
                        path:''
                    },*/
                ],
            },
            // {
            //         name:'Services',
            //         path:'/services'
            // },
            {
                name:'Pricing',
                path:'/pricing'
            },
            {
                name:'Career',
                path:'/career'
            },
            {
                name:'contact',
                    path:'/contact-us'
            }
            ]
        }
    },
     mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
         toggleSidebar(){
           this.sidebar = !this.sidebar
        },  
    }

}
</script>

<style>

</style>