<template>
  <div>

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />

    <!--====== OFFCANVAS MENU PART ENDS ======-->
   
    <!--====== PART START ======-->
    
    
    
    <!--====== PART ENDS ======-->

    <!--====== APPIE HERO PART START ======-->

    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>

    <!--====== APPIE HERO PART ENDS ======-->
    <title-area/>
    <!--====== APPIE COUNTER PART START ======-->
    <PriceingHome title="Simple pricing for Everyone" description="" />
    <!-- reference home two -->    
    <!-- reference home three -->
    <get-started/>
    
    <!--====== APPIE PROJECT 3 PART ENDS ======-->

    <!--====== APPIE FOOTER PART START ======-->
    <!-- reference home three -->
    <footer-home-two class="appie-footer-area appie-footer-about-area"/>
    
    <!--====== APPIE FOOTER PART ENDS ======-->


    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top back-to-top-3">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template> 

<script>
//import icon_one from "@/assets/images/icon/1.png" 
//import icon_two from "@/assets/images/icon/2.png" 
//import icon_three from "@/assets/images/icon/3.png" 
//import icon_four from "@/assets/images/icon/4.png" 
import video_bg_thumb from "@/assets/images/video-thumb-1.jpg"
import SidebarHomeTwo from '../Sidebar/SidebarHomeTwo.vue'
import FooterHomeTwo from '../Footer/FooterHomeTwo.vue'
import HeaderProduct from '../Header/HeaderProduct.vue'
import TitleArea from './TitleArea.vue'
//import Faq from './Faq.vue'
//import Testimonial from './Testimonial.vue'
import traffic_thumb from '@/assets/images/traffic-thumb.webp'
import PriceingHome from './PriceingHome.vue'
import GetStarted from '../Footer/GetStarted.vue'
//import ShowCaseHomeTwo from './ShowCaseHomeTwo.vue'

export default {
	components: { SidebarHomeTwo, TitleArea, PriceingHome, GetStarted, HeaderProduct, FooterHomeTwo },
        data(){
        return{
            traffic_thumb:traffic_thumb,
            sidebar:false,
            video_bg_thumb:video_bg_thumb,
            navs:[
                {
                    name:'home',                    
                    path:'/',                    
                },
                {
                    name:'About us',
                    path:'/about-us'            
                },
                {
                    name:'Zoho',
                    path:'/zoho'            
                },
                {
                    name:'Industries',
                        childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /*{ 
                            name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },
                        { 
                            name:'Hospitals and Clinics',
                            path:''
                        },*/
                    ],
                },
                // {
                //     name:'Services',
                //     path:'/services'
                // },
                {
                    name:'Pricing',
                    path:'/pricing'
                },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ],
            features:[
                {
                    title: 'Micro Finance Loan',
                    bg:'',// create class for style
                    description:'Best Micro Finance Loan Software for all kinds of Group.'

                },
                {
                    title: 'Personal Loan',
                    bg:'item-3',// create class for style
                    description:'Personal Loan servicing software with more features and loan tracking.'

                },
                {
                    title: 'Business Loan',
                    bg:'item-4',// create class for style
                    description:'Customizable Software with best security features for all Business Loans.'

                },
                {
                    title: 'MSME Loan',
                    bg:'item-4',// create class for style
                    description:"Small Business Loan Software, Handles Every Digital Lending Need You Have."

                },
            ], 
            itemsGrid:[
                {
                    count:1,
                    icon:'layout-sidebar',
                    title:'Dashboard',
                    content:'Quick Report, Overall Financial status',
                    customClass:''

                },
                {
                    count:2,
                    icon:'file-earmark-text',
                    title:'Loan Application',
                    content:'Deals, Leads, Active Loans',
                    customClass:'item-2'

                },
                {
                    count:3,
                    icon:'file-earmark-check',
                    title:'Loan Approval',
                    content:'Credit Officer Approval, Branch Manager Approval, Accounts Manager Approval, Loan Disbursement',
                    customClass:'item-3',

                },
                {
                    count:4,
                    icon:'cash',
                    title:'EMI collection',
                    content:'Day Wise Collection, Pending Collection, Branch Collection',
                    customClass:''

                },
                {
                    count:5,
                    icon:'wallet',
                    title:'Ledgers',
                    content:'Trial Ledger, P & L Ledger, Day Wise Report, Monthly Report',
                    customClass:'item-2'

                },
                {
                    count:6,
                    icon:'file-earmark-bar-graph',
                    title:'Collection Reports',
                    content:'Collection Sheet, Collection Projection Report, Collection Report, Over Due Report',
                    customClass:'item-3'

                },
                {
                    count:7,
                    icon:'file-earmark-bar-graph',
                    title:'Other Reports',
                    content:'Bussiness Asset Report, Disbursement Report, Loan Closure Report, Credit Bureau Report, NEFT/RTGS Request',
                    customClass:'item-3'

                },
                {
                    count:7,
                    icon:'file-earmark-bar-graph',
                    title:'Software Database',
                    content:"Our software supports easy import/export the data's such as Customer Database, Active/Completed/Pending Loans, P & L Ledger, and which ever required.",
                    customClass:'item-3'

                },
            ],
        }
    },
   mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },

    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar(){
           this.sidebar = !this.sidebar
        },   
    }

}
</script>