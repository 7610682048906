<template>
        <!-- Contact Start -->
        <section class="contact-section" v-if="jobs && jobs.length">
            <div class="container" v-for="job of jobs" v-bind:key="job.id">
                <div class="row text-center mb-5">
                    <div class="col-lg-12 col-md-12">
                        <div class="appie-section-title">
                            <h3 class="text-primary">{{job.job_title}}</h3>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <div class="contact-form">
                            <p class="">Apply for "{{job.job_title}}"</p>
                            <form action="#" method="post" class="mt-5" autocomplete="off" enctype="multipart/form-data">
                                <input type="hidden" name="application_id" :value="job.id">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="name" class="font-bold">Name<span class="text-danger">*</span></label>
                                        <input type="text" name="name" id="name" v-model="career.name" placeholder="Name">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="name" class="font-bold">Email<span class="text-danger">*</span></label>
                                        <input type="email" name="email" id="email" v-model="career.email" placeholder="Email Address">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="name" class="font-bold">Phone No.<span class="text-danger">*</span></label>
                                        <input type="text" name="phone" id="phone" v-model="career.phone" placeholder="Phone Number">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="name" class="font-bold">Qualification<span class="text-danger">*</span></label>
                                        <input type="text" name="qualification" id="qualification" v-model="career.qualification" placeholder="Qualification">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="name" class="font-bold">Passed out year<span class="text-danger">*</span></label>
                                        <select name="passed_out" id="passed_out" v-model="career.passed_out" placeholder="Passed out year">
                                            <option value="">--Select Passed out year--</option>
                                            <option value="2018">2018</option>
                                            <option value="2019">2019</option>
                                            <option value="2020">2020</option>
                                            <option value="2021">2021</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="name" class="font-bold">Experience<span class="text-danger">*</span></label>
                                        <select name="experience" id="experience" v-model="career.experience" placeholder="Experience">
                                            <option value="">--Select Experience--</option>
                                            <option value="Student">Student</option>
                                            <option value="Freshers">Freshers</option>
                                            <option value="0-1 Year">0-1 Year</option>
                                            <option value="1-2 Years">1-2 Years</option>
                                            <option value="1-2 Years">1-2 Years</option>
                                            <option value="More than 2 Years">More than 2 Years</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="name" class="font-bold">Location<span class="text-danger">*</span></label>
                                        <select name="location" id="location" v-model="career.location" placeholder="Location">
                                            <option value="">--Select District--</option>
                                            <option value="Ariyalur">Ariyalur</option>
                                            <option value="Chennai">Chennai</option>
                                            <option value="Coimbatore">Coimbatore</option>
                                            <option value="Cuddalore">Cuddalore</option>
                                            <option value="Dharmapuri">Dharmapuri</option>
                                            <option value="Dindigul">Dindigul</option>
                                            <option value="Erode">Erode</option>
                                            <option value="Kanchipuram">Kanchipuram</option>
                                            <option value="Kanyakumari">Kanyakumari</option>
                                            <option value="Karur">Karur</option>
                                            <option value="Krishnagiri">Krishnagiri</option>
                                            <option value="Madurai">Madurai</option>
                                            <option value="Nagapattinam">Nagapattinam</option>
                                            <option value="Namakkal">Namakkal</option>
                                            <option value="Nilgiris">Nilgiris</option>
                                            <option value="Perambalur">Perambalur</option>
                                            <option value="Pudukkottai">Pudukkottai</option>
                                            <option value="Ramanathapuram">Ramanathapuram</option>
                                            <option value="Salem">Salem</option>
                                            <option value="Sivaganga">Sivaganga</option>
                                            <option value="Thanjavur">Thanjavur</option>
                                            <option value="Theni">Theni</option>
                                            <option value="Tiruchirappalli">Tiruchirappalli</option>
                                            <option value="Tirunelveli">Tirunelveli</option>
                                            <option value="Tiruvallur">Tiruvallur</option>
                                            <option value="Tiruvannamalai">Tiruvannamalai</option>
                                            <option value="Tiruvarur">Tiruvarur</option>
                                            <option value="Tuticorin">Tuticorin</option>
                                            <option value="Vellore">Vellore</option>
                                            <option value="Villupuram">Villupuram</option>
                                            <option value="Virudhunagar">Virudhunagar</option>
                                        </select>
                                    </div>                                    
                                    <div class="col-md-6">
                                        <label for="name" class="font-bold">Upload Resume<span class="text-danger">*</span></label>
                                        <input type="file"  id="file" ref="file" @change="resumeUpload">
                                    </div>
                                    <div class="col-md-12 mb-3 text-center" id="error-alert">
                                        
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <input type="submit" name="submit" value="Submit" @click.prevent="submitResume()">
                                    </div> 
                                </div>                                
                            </form>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="contact">
                            <h3>{{job.job_title}}</h3>
                            <div class="card p-3">
                                <div class="mb-3">
                                    <p><span style="font-weight:600">Qualification : </span> {{job.qualification}}</p>
                                    <p><span style="font-weight:600">Experience : </span> {{job.experience}}</p>
                                    <p><span style="font-weight:600">No.of Vacant : </span> {{job.no_of_vacant}}</p>
                                    <p><span style="font-weight:600">Gender : </span> {{job.gender}}</p>
                                </div>  
                                <div class="">
                                    <pre style="white-space: pre-wrap;word-break: normal;font-family: 'Roboto';font-size: 14px;color: #505056;">{{br2nl(job.job_description)}}</pre>
                                </div> 
                            </div>                             
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Contact End -->
</template>

<script>
import axios from 'axios';

export default {
    data(){
		return{
			career:{
				name:null,
				email:null,
                phone:null,
                qualification:null,
                passed_out:null,
                experience:null,
                location:null,
			},
            jobs: [],
            file: ''			
		}     
	},
    mounted() {
        this.getJob()
    },
    methods:{
        async getJob(){
            var job_id = this.$route.params.job_id;
            await axios.get("https://adm.roftr.com/api/careers/get_careers/"+job_id)
            .then(response => {
                // JSON responses are automatically parsed.
                this.jobs = response.data
            })
        },
		submitResume(){ 
            let formData = new FormData();
            const application_id = document.querySelector("input[name=application_id]").value;
            formData.append('job_id', application_id);
            formData.append('name', this.career.name);
            formData.append('email', this.career.email);
            formData.append('phone', this.career.phone);
            formData.append('qualification', this.career.qualification);
            formData.append('passed_out', this.career.passed_out);
            formData.append('experience', this.career.experience);
            formData.append('location', this.career.location);
            formData.append('resume', this.file);

            document.getElementById("error-alert").innerHTML = "<div class='alert-msg infomsg'><div class='text-primary'>Processing...</div></div>";

			axios.post("https://adm.roftr.com/api/careers/submit_career",formData,{  headers: { 'Content-Type': 'multipart/form-data' } })
			.then((response)=> {			 
                var data = response.data;          
                if(data == "Success"){
                    document.getElementById("error-alert").innerHTML = "<div class='alert-msg successmsg'><div class='text-success'>Application Submitted Successfully!</div></div>";                
                    setTimeout(function(){ location.reload(); },2000);
                }else{
                    document.getElementById("error-alert").innerHTML = "<div class='alert-msg errormsg'><div class='text-danger'>Application Not Submitted!</div></div>";
                }          
			})
			.catch((error)=>{
				console.log(error);
			}) 
		},
        resumeUpload (event) {
            this.file = event.target.files[0];
        },
        br2nl(value){
            return value.replace(/<br[^>]*>/gi, "\n"); 
        }
	}
}
</script>

<style>

</style>