<template>
  
    <div class="appie-testimonial-about-area pb-45">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">What clients say about us</h3>
                        <p>The app provides design and digital marketing.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="testimonial-about-slider-active" style="position :relative">
                        <span @click.prevent="showPrev" class="prev slick-arrow" style="display: block;"><i class="fal fa-arrow-left"></i></span>
                        <VueSlickCarousel v-bind="settingsForArticle" ref="articleCarosel">
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Initiall we had 2 different software for Online Movie Ticket booking and for Box Office. With Roftr we are using Single Window application for both Offline and Online Ticket sales.</p>
                            </div>
                            </div>
                          
                          
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Not just single screen, Roftr organised all our group of theatres in single application and splits movie booking money to respective theatre accounts in automatice way. No manually works here, everything is computersied, including DCR to Representative bata.</p>
                            </div>
                            </div>
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Screen layout was setup with space as per our requirements, since its not readymade software, we have customized ticket booking software as per our need.</p>
                            </div>
                            </div>
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Very much convenient with our Sivam Theatre Movie ticket booking software, always gets updated anytime for movie and price settings.</p>
                            </div>
                            </div>
                           
                           
                        </VueSlickCarousel>
                        <span @click.prevent="showNext" class="next slick-arrow" style="display: block;"><i class="fal fa-arrow-right"></i></span>
                    </div>
                </div>
                <div class="col-lg-4 col-md-8 col-sm-10">
                    <div class="testimonial-box-about-slider-small-active">
                        <VueSlickCarousel v-bind="settingsForPeople" ref="peopleCarosel">
                            <div class="item">
                                <div class="thumb">
                                    <img src="@/assets/images/senthil-kumaran-theatres.png" alt="senthil-kumaran-theatres"/>
                                </div>
                                <div class="content text-center">
                                    <h5 class="title">Senthil Kumaran Theatres</h5>
                                    <span>Coimbatore</span>
                                </div>
                            </div>
                            <div class="item">
                                <div class="thumb">
                                    <img src="@/assets/images/karur-cinemas-logo.png" alt="karur cinemas logo"/>
                                </div>
                                <div class="content text-center">
                                    <h5 class="title">Karur Cinemas</h5>
                                    <span>Karur</span>
                                </div>
                            </div>
                            <div class="item">
                                <div class="thumb">
                                    <img src="@/assets/images/kavithalaya-karur.png" alt="kavithalaya-karur"/>
                                </div>
                                <div class="content text-center">
                                    <h5 class="title">Kavithalaya</h5>
                                    <span>Karur</span>
                                </div>
                            </div>
                            <div class="item">
                                <div class="thumb">
                                    <img src="@/assets/images/sivam-theatre-logo.png" alt="sivam theatre logo"/>
                                </div>
                                <div class="content text-center">
                                    <h5 class="title">Sivam Theatre</h5>
                                    <span>Mettupalayam</span>
                                </div>
                            </div>
                            
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
        components:{ VueSlickCarousel},
        data(){ 
            return{
                settingsForArticle: {
                    autoplay: false,
                    arrows:false,
                    dots:false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
                settingsForPeople:{
                    centerMode: true,
                    centerPadding: '0px',
                    autoplay: false,
                    arrows:false,
                    dots:false,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            }
        },
        methods:{ 
            showNext() {
                this.$refs.articleCarosel.next()
                this.$refs.peopleCarosel.next()
            },
            showPrev(){
                this.$refs.articleCarosel.prev()
                this.$refs.peopleCarosel.prev()
            },
        }


}
</script>

<style>
.testimonial-about-slider-active .testimonial-box{
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.slick-slide{
    padding: 0;
}

</style>