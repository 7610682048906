<template>
  <div>
   
    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    <title-area/>
     <section class="appie-about-page-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-about-page-content appie-zoho-page-content">
                        <h3 class="title">Roftr Clouds LLP - Zoho Partner Company, Coimbatore, India.</h3>
                        <p>Roftr Clouds LLP is a reputable Zoho Partner company that specializes in providing comprehensive support and services related to Zoho's suite of cloud-based software solutions. Roftr Clouds LLP has established itself as a trusted partner for businesses seeking to leverage Zoho's powerful tools to enhance their productivity, streamline operations, and achieve their digital transformation goals.</p>
                        <p>Roftr Clouds LLP stands out as a top-tier Zoho Partner company that offers comprehensive support and services for businesses looking to harness the power of Zoho's cloud-based software solutions. With their expertise, customer-centric approach, and commitment to long-term success, Roftr Clouds LLP is a trusted partner in the journey towards digital transformation and enhanced productivity.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <services-list />
     <faq class="pb-95 pt-90" title="Frequently asked questions" description="Your common questions, answered."/>
    <get-started/>
    <footer-home-two class="appie-footer-area appie-footer-about-area"/>
    

    <div class="back-to-top back-to-top-2">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
</div>
</template> 

<style>
.appie-zoho-page-content:before{
    background-image: url(../../assets/images/zoho-partner-coimbatore-roftr.jpg) !important;
}
</style>
<script>

import SidebarHomeTwo from '../Sidebar/SidebarHomeTwo.vue'
import FooterHomeTwo from '../Footer/FooterHomeTwo.vue'
import HeaderProduct from '../Header/HeaderProduct.vue'
import TitleArea from './TitleArea.vue'
import ServicesList from './ServicesList.vue'
import Faq from './Faq.vue'
import GetStarted from '../Footer/GetStarted.vue'


export default {
	components: { SidebarHomeTwo, TitleArea, ServicesList, Faq, GetStarted, HeaderProduct, FooterHomeTwo },
        data(){
        return{
            sidebar:false,
            navs:[
                {
                    name:'home',                    
                    path:'/',                    
                },
                {
                    name:'About us',
                    path:'/about-us'            
                },
                {
                    name:'Zoho',
                    path:'/zoho'            
                },
                {
                    name:'Industries',
                        childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /*{ 
                            name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },
                        { 
                            name:'Hospitals and Clinics',
                            path:''
                        },*/
                    ],
                },
                // {
                //     name:'Services',
                //     path:'/services'
                // },
                /*
                {
                    name:'Zoho',
                    path:'/zoho'
                },
                */
                {
                    name:'Pricing',
                    path:'/pricing'
                },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ],
        }
    },
   mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },

    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar(){
           this.sidebar = !this.sidebar
        },   
    }

}
</script>