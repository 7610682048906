<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar" :menuItems="navs"/>

    <!--====== OFFCANVAS MENU PART ENDS ======-->
   
    <!--====== APPIE HEADER PART START ======-->
    
    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    
    <!--====== APPIE HEADER PART ENDS ======-->
    <title-area/>
    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE HEADER PART ENDS ======-->


    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE SERRVICE 2 PART START ======-->
    
    <!--====== APPIE SERRVICE 2 PART ENDS ======-->

    <!--====== APPIE TEAM PART START ======-->    
    <!--====== APPIE TEAM PART ENDS ======-->

    <!--====== APPIE SPONSER PART START ======-->
    
    <faq-home-one title="Join Us" description="Current Openings"/>
    
    <!--====== APPIE SPONSER PART ENDS ======-->

    <!--====== APPIE SPONSER PART ENDS ======-->

    <!--====== APPIE SPONSER PART ENDS ======-->
    <!--<get-started/>-->
    <!--====== APPIE FOOTER PART START ======-->
    
    <footer-home-two class="appie-footer-area appie-footer-about-area"/>
    
    <!--====== APPIE FOOTER PART ENDS ======-->


    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import FooterHomeTwo from '../Footer/FooterHomeTwo.vue'
import HeaderProduct from '../Header/HeaderProduct.vue'
import SidebarHomeTwo from '../Sidebar/SidebarHomeTwo.vue'
//import GetStarted from '../Footer/GetStarted.vue'
import FaqHomeOne from './FaqHomeOne.vue'
import TitleArea from './TitleArea.vue'
export default {
	components: { SidebarHomeTwo, HeaderProduct, TitleArea, FaqHomeOne, FooterHomeTwo },
    data(){
        return{
            sidebar:false,            
            navs:[
                {
                    name:'home',
                    path:'/'
                },
                {
                    name:'About us',
                    path:'/about-us'
                },
                {
                    name:'Zoho',
                    path:'/zoho'            
                },
                {
                    name:'Industries',
                        childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /* { 
                           name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },
                        { 
                            name:'Hospitals and Clinics',
                            path:''
                        },*/
                    ],
                },
                // {
                //     name:'Services',
                //     path:'/services'
                // },
                {
                    name:'Pricing',
                    path:'/pricing'
                },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ]
        }
    },
     mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
         toggleSidebar(){
           this.sidebar = !this.sidebar
        },  
    }

}
</script>

<style>

</style>