<template>
  <div>
    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    <title-area/>
    
    <we-build-everything/>

    <services-home-eight/>
    
    <traffic :traffic_thumbnail="traffic_thumb" small_title="" big_title="Online Ticket Booking." description="" :features="features"/>
    
    <work-home-eight/>


    <features/>
    
    <faq class="pb-95 pt-90" title="Frequently asked questions" description="Your common questions, answered."/>
    
    <testimonial/>
    <!--====== APPIE SPONSER PART ENDS ======-->
    <sponser-home-two title="Our Clients" more_title="" description="Best Investors for the growth of our Product."/>
    
    <get-started/>

    <footer-home-two class="appie-footer-area appie-footer-about-area"/>
    

    <div class="back-to-top back-to-top-8">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import traffic_thumb from '@/assets/images/traffic-thumb.png'
import SidebarHomeTwo from '../../Sidebar/SidebarHomeTwo.vue'
import HeaderProduct from '../../Header/HeaderProduct.vue'
import TitleArea from './TitleArea.vue'

import WeBuildEverything from './WeBuildEverything.vue'
import FooterHomeTwo from '../../Footer/FooterHomeTwo.vue'

//import HeroHomeEight from './HeroHomeEight.vue'
import Features from './Features.vue'
import Faq from './Faq.vue'

import Testimonial from './Testimonial.vue'
import Traffic from './Traffic.vue'
import ServicesHomeEight from './ServicesHomeEight.vue'
import GetStarted from '../../Footer/GetStarted.vue'
import SponserHomeTwo from '../../Footer/SponserHomeTwo.vue'
import WorkHomeEight from './WorkHomeEight.vue'
export default {
	components: { SidebarHomeTwo, HeaderProduct, Features, Traffic, TitleArea, SponserHomeTwo, Faq, Testimonial, WeBuildEverything, ServicesHomeEight, WorkHomeEight, GetStarted, FooterHomeTwo },
    data(){
        return{
            traffic_thumb:traffic_thumb,
            sidebar:false,
            navs:[
                {
                    name:'home',
                    path:'/'
                },
                {
                    name:'About us',
                    path:'/about-us'
                },
                {
                    name:'Zoho',
                    path:'/zoho'            
                },
                {
                    name:'Industries',
                        childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /*{ 
                            name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },
                        { 
                            name:'Hospitals and Clinics',
                            path:''
                        },*/
                    ],
                },
                // {
                //     name:'Services',
                //     path:'/services'
                // },
                {
                    name:'Pricing',
                    path:'/pricing'
                },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ],
            features:[
                {
                    title: 'GST Report',
                    bg:'',// create class for style
                    description:'We have a computerized GST report with a serial number. Daily and Monthly reports based on Online and counter sales with tax calculation.'

                },
                {
                    title: 'Secured Payment Gateway',
                    bg:'item-2',// create class for style
                    description:'Our system comes with a default Worldline gateway for Theatres, and also we can add any other gateway like ICICI, HDFC, Razorpay - as required.'

                },
                {
                    title: 'Multiple Screen',
                    bg:'item-3',// create class for style
                    description:'Our system supports multiple Screens in a single application for your theatre with a unique Screen layout with seating &amp; individual reports for each screen.'

                },
                {
                    title: 'Social Distancing Seats',
                    bg:'item-4',// create class for style
                    description:'Our tool helps easily enable-disable Social Distancing Seats and the option to switch back to total seat occupancy. Customers can book only available seats for booking.'

                },
                {
                    title: 'Ticket Cancellation',
                    bg:'item-5',// create class for style
                    description:'Ticket cancellation is available at Manager login for box office sales. If any shows are cancelled, Bulk cancellation for both online and Offline tickets.'

                },
                {
                    title: 'Unique Price Settings',
                    bg:'item-6',// create class for style
                    description:'Unique price setting tool helps for Festival season movies and 3D shows, which can be applied to each Show Date/Time with Service charge and other taxes will be included.'

                },
                {
                    title: 'Detailed Payment List Page',
                    bg:'item-7',// create class for style
                    description:'The payment list page will show information on each ticket sold online/counter. The system generates a unique ticket code to track the salesperson, date, and time and includes the report.'

                },
                {
                    title: 'Customized Sales Report',
                    bg:'item-8',// create class for style
                    description:'View complete Collection report, Quick Report, Screen Report, Complimentary Report, Cancelled ticket report, Online Sales report, Individual salesperson report and DCR report.'

                },
                {
                    title: 'User Level Control',
                    bg:'item-9',// create class for style
                    description:'Create new User levels like Manager, Accounts, Box office salesperson with control access of View Only reports and options to enable selected menus to respective users.'

                },
                {
                    title: 'SMS & Email Alerts',
                    bg:'item-10',// create class for style
                    description:'SMS and Email alert online customers about their booking information, Daily sales reports to Theatre owners/managers and log-in with OTP for box office salespeople.'

                },
                {
                    title: 'User Friendly Application',
                    bg:'item-1',// create class for style
                    description:'No system knowledge is required to work on our ticket booking application. A secured User-friendly admin panel helps sell tickets and generate a report with just a Click.'

                },
                {
                    title: 'Social Media Connections',
                    bg:'item-2',// create class for style
                    description:'Our application connected with Social Media networks like Twitter, Facebook, Instagram, and Youtube to notify the followers for upcoming movies and other news.'

                },
                {
                    title: 'Customers Helpdesk',
                    bg:'item-3',// create class for style
                    description:'Our helpdesk tool helps customers post their issues in booking or any payment-related issues with complete tracking history, maintained and monitored by the Management Team.'

                },
                {
                    title: 'Avoid Black Tickets',
                    bg:'item-4',// create class for style
                    description:'Our ticket restriction tool helps set up booking quota to customers by limiting the number of transactions per Day/Email ID/Mobile/IP address with enable/disable option.'

                },
                {
                    title: 'Database Backup',
                    bg:'item-5',// create class for style
                    description:'We have customized Database backup settings, which helps run the whole website backup, respective module data backup daily or set up an auto backup with Date/Time as required.'

                },
                {
                    title: 'Ticket Printing',
                    bg:'item-6',// create class for style
                    description:'A thermal printer is the best choice to print tickets at box-office & Online customers with Payment ID. Self KIOSK ticketing tool is also available for customers to book tickets at Theatre.'

                },
                {
                    title: 'Owners Login',
                    bg:'item-8',// create class for style
                    description:'Theatre owners mobile app helps to view the report on daily sales, show wise, date wise, monthly and overall business year. Analytics modules help identify Movie/Hero based sales reports.'

                },
                {
                    title: 'WhatsApp Alerts',
                    bg:'item-9',// create class for style
                    description:'Whatsapp modules are optional features used for Booking information sent to Online customers, New movie alerts or any other theatre-related news with an opt-out option.'

                },
            ], 
        }
    },
     mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
         toggleSidebar(){
           this.sidebar = !this.sidebar
        },  
    }

}
</script>

<style>

</style>