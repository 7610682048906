<template>
  <div>
      <!--====== PRELOADER PART START ======-->
    <div v-if="showloading">
        <loader-appie/>
    </div>
    <div v-else>
  

    <!--====== OFFCANVAS MENU PART START ======-->
    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
    <!--====== OFFCANVAS MENU PART ENDS ======-->
   
    <!--====== APPIE HEADER PART START ======-->
    
   <header-home-two @toggleSidebar="toggleSidebar" :menuItems="navs"/> 
    
    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE HERO PART START ======-->

    <home-banner offer_title="ZOHO" heading="Avail Your Online Store In A Short Period" more_heading="" description="Transform your business into complete Automation." :hero_img="hero_img"/>
    
    <!--====== APPIE HERO PART ENDS ======-->

    <!--====== APPIE SERRVICE 2 PART START ======-->
    
    <services-home-two title="Website and Software Development Company, Coimbatore" description="At Roftr, We provide quality solution that are sensible and that makes a positive impact about you amongst your customers. We do have the professionals who know what they are doing and who truly believe in what they do, not just in their paychecks."/>
    <!--====== APPIE SERRVICE 2 PART ENDS ======-->

    <!--====== APPIE ABOUT PART START 
    
    <full-stack-developer title="Full Stack Developer Training" description="We offer Software Developer traning for final year IT students and IT job seekers." />
    
    ======-->
    <!--====== APPIE ABOUT PART ENDS ======-->

    <!--====== APPIE FEATURES 2 PART START ======-->
    
    <!--====== APPIE FEATURES 2 PART ENDS ======-->

    <!--====== APPIE COUNTER PART START ======-->
        
    <!--====== APPIE COUNTER PART ENDS ======-->

    <!--====== APPIE VIDEO PLAYER PART START ======-->
    
   <!--====== APPIE VIDEO PLAYER PART ENDS ======-->


    <!--====== APPIE TESTIMONIAL 2 PART START ======-->
    
    <testimonial-home-two :sliderContents="sliderContents"/>
    
    <!--====== APPIE TESTIMONIAL 2 PART ENDS ======-->

    <!--====== APPIE SPONSER PART START ======--> 
    
    <sponser-home-two title="Our Clients" more_title="" description="Best Investors for the growth of our Product."/>
    

    <!--====== APPIE SPONSER PART ENDS ======-->

    <!--====== APPIE FOOTER PART START ======-->
    
    <footer-home-two/>

    
    <!--====== APPIE FOOTER PART ENDS ======-->


    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top back-to-top-2">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
</div>
</template> 

<script>
import video_bg_thumb from "@/assets/images/video-thumb-1.jpg"
import testmonialAvater from "@/assets/images/testimonial-user.png"
import hero_img from '@/assets/images/home-ecom-banner.webp'
import SidebarHomeTwo from '../Sidebar/SidebarHomeTwo.vue'

//import FullStackDeveloper from './FullStackDeveloper.vue'
import FooterHomeTwo from '../Footer/FooterHomeTwo.vue'
import HeaderHomeTwo from './HeaderHomeTwo.vue'
//import HeroHomeTwo from './HeroHomeTwo.vue'
import HomeBanner from './HomeBanner.vue'

import ServicesHomeTwo from './ServicesHomeTwo.vue'
import SponserHomeTwo from '../Footer/SponserHomeTwo.vue'
import TestimonialHomeTwo from './TestimonialHomeTwo.vue'
import LoaderAppie from '../LoaderAppie.vue'
export default {
	components: { HeaderHomeTwo, HomeBanner, ServicesHomeTwo, TestimonialHomeTwo, SponserHomeTwo, FooterHomeTwo, SidebarHomeTwo, LoaderAppie, },
    data(){
        return{
            sidebar:false,
            testmonialAvater:testmonialAvater,
            showloading:false,
            navs:[
                {
                    name:'home',                    
                    path:'/',                    
                },
                {
                    name:'About us',
                    path:'/about-us'            
                }, 
                {
                    name:'Zoho',
                    path:'/zoho'            
                },                
                {
                    name:'Industries',
                        childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /*{ 
                            name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },
                        { 
                            name:'Hospitals and Clinics',
                            path:''
                        },*/
                    ],
                },
                // {
                //     name:'Services',
                //     path:'/services'
                // },
                {
                    name:'Pricing',
                    path:'/pricing'
                },

                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ],
            sliderContents:[
                {
                    title:'Shree Devi Textile',
                    date:'Coimbatore',
                    description:'User friendly e-commerce application, Long-term support for our online store and always move on to latest technologies. ',
                    ratings:5,
                    avatar:testmonialAvater,
                },
                {
                    title:'Karur Cinemas',
                    date:'Karur',
                    description:'Roftr Made everything easy for us. No paper works, everything is computerized in our theatre. Good service.',
                    ratings:5,
                     avatar:testmonialAvater,
                },
                {
                    title:'Senthil Group of Companies',
                    date:'Coimbatore',
                    description:'Senthil Kumaran Theatres and our other corporate web applications handled by Roftr, service and support are their key success.',
                    ratings:5,
                     avatar:testmonialAvater,
                },
                {
                    title:'Uday Sourcing',
                    date:'Tirupur',
                    description:'The best destination for people wanting an e-commerce website or any type of website. Best service by the professional and experienced team they have.',
                    ratings:5,
                    avatar:testmonialAvater,
                }
            ],
            hero_img:hero_img,
            video_bg_thumb:video_bg_thumb
        }
    },
     mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    created() {
       this.preLoading();
        document.addEventListener('scroll', this.topToBottom)
    },
    methods:{
        preLoading(){
            setTimeout(()=>{
                this.showloading= false    
            },1500)
        },
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
         toggleSidebar(){
           this.sidebar = !this.sidebar
        },  
    }

}
</script>

<style>

</style>